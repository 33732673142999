<template>
  <app-overlay
    class="applicant-edit"
    backdrop
  >
    <modal-card
      data-test="applicant-edit-modal"
      class="applicant-edit__form"
      @close="handleClose"
    >
      <header>
        <p class="applicant-edit__title">{{ $t(`${langPath}.title`) }}</p>
      </header>
      <candidate-form
        :candidate="candidate"
        :submit-button-label="$t(`${langPath}.submitButton`)"
        :is-sending="isSending"
        @submit="handleSubmit"
      />
    </modal-card>
  </app-overlay>
</template>

<script>
import CandidateForm from '../CandidateAdd/components/CandidateForm'
import ModalCard from '@/components/ModalCard'
import { TheNotificationCardList } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { STAGES, APPLICANTS } from '@/store/namespaces'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'CandidateAdd',

  components: {
    CandidateForm,
    ModalCard
  },

  data () {
    return {
      langPath: __langpath,
      isSending: false
    }
  },
  computed: {
    ...applicantsHelper.mapGetters(['stageOfApplicant', 'applicant']),
    candidate () { return this.applicant?.candidate }
  },
  created () {
    this.setApplicantId(this.$route.params.applicantId)
  },
  methods: {
    ...applicantsHelper.mapActions(['setApplicantId', 'updateCandidate']),
    ...stagesHelper.mapActions(['getStage']),
    handleClose () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: {
          workflowId: this.$route.params.workflowId
        }
      })
    },
    handleSubmit (candidate) {
      this.isSending = true

      this.updateCandidate({
        candidateId: this.candidate.id,
        applicantId: this.applicant.id,
        name: candidate.name,
        email: candidate.email,
        phone: candidate.phone,
        resume: candidate.resume,
        portfolioUrl: candidate.portfolioUrl,
        linkedinUrl: candidate.linkedinUrl,
        sourceInfo: candidate.sourceInfo
      }).then((_) => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.editSuccess`),
          modifier: 'success'
        })
        this.$smartlook('track', 'edit_candidate_confirmed')
        this.$router.push({
          name: 'ApplicantInterview',
          params: this.$route.params
        })

        // TODO: remove it when refactor de Applicants in Stages
        return this.getStage({
          positionId: this.$route.params.positionId,
          stageId: this.stageOfApplicant.id
        })
      }).catch((_) => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.editError`),
          modifier: 'error'
        })
      }).finally((_) => {
        this.isSending = false
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-edit {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  &__form {
    height: 100vh;
    margin-bottom: 72px;
    width: 100vw;

    @include breakpoint(lg) {
      height: auto;
      left: 25vw;
      position: absolute;
      top: $base*12;
      width: 50vw;
    }
  }

  &__title {
    @extend %subtitle1;
  }
}
</style>
